import _pseudomap from "pseudomap";
import _util from "util";
import _yallist from "yallist";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = LRUCache; // This will be a proper iterable 'Map' in engines that support it,
// or a fakey-fake PseudoMap in older versions.

var Map = _pseudomap;
var util = _util; // A linked list to keep track of recently-used-ness

var Yallist = _yallist; // use symbols if possible, otherwise just _props

var symbols = {};
var hasSymbol = typeof Symbol === "function";
var makeSymbol;
/* istanbul ignore if */

if (hasSymbol) {
  makeSymbol = function (key) {
    return Symbol.for(key);
  };
} else {
  makeSymbol = function (key) {
    return "_" + key;
  };
}

function priv(obj, key, val) {
  var sym;

  if (symbols[key]) {
    sym = symbols[key];
  } else {
    sym = makeSymbol(key);
    symbols[key] = sym;
  }

  if (arguments.length === 2) {
    return obj[sym];
  } else {
    obj[sym] = val;
    return val;
  }
}

function naiveLength() {
  return 1;
} // lruList is a yallist where the head is the youngest
// item, and the tail is the oldest.  the list contains the Hit
// objects as the entries.
// Each Hit object has a reference to its Yallist.Node.  This
// never changes.
//
// cache is a Map (or PseudoMap) that matches the keys to
// the Yallist.Node object.


function LRUCache(options) {
  if (!((this || _global) instanceof LRUCache)) {
    return new LRUCache(options);
  }

  if (typeof options === "number") {
    options = {
      max: options
    };
  }

  if (!options) {
    options = {};
  }

  var max = priv(this || _global, "max", options.max); // Kind of weird to have a default max of Infinity, but oh well.

  if (!max || !(typeof max === "number") || max <= 0) {
    priv(this || _global, "max", Infinity);
  }

  var lc = options.length || naiveLength;

  if (typeof lc !== "function") {
    lc = naiveLength;
  }

  priv(this || _global, "lengthCalculator", lc);
  priv(this || _global, "allowStale", options.stale || false);
  priv(this || _global, "maxAge", options.maxAge || 0);
  priv(this || _global, "dispose", options.dispose);
  this.reset();
} // resize the cache when the max changes.


Object.defineProperty(LRUCache.prototype, "max", {
  set: function (mL) {
    if (!mL || !(typeof mL === "number") || mL <= 0) {
      mL = Infinity;
    }

    priv(this || _global, "max", mL);
    trim(this || _global);
  },
  get: function () {
    return priv(this || _global, "max");
  },
  enumerable: true
});
Object.defineProperty(LRUCache.prototype, "allowStale", {
  set: function (allowStale) {
    priv(this || _global, "allowStale", !!allowStale);
  },
  get: function () {
    return priv(this || _global, "allowStale");
  },
  enumerable: true
});
Object.defineProperty(LRUCache.prototype, "maxAge", {
  set: function (mA) {
    if (!mA || !(typeof mA === "number") || mA < 0) {
      mA = 0;
    }

    priv(this || _global, "maxAge", mA);
    trim(this || _global);
  },
  get: function () {
    return priv(this || _global, "maxAge");
  },
  enumerable: true
}); // resize the cache when the lengthCalculator changes.

Object.defineProperty(LRUCache.prototype, "lengthCalculator", {
  set: function (lC) {
    if (typeof lC !== "function") {
      lC = naiveLength;
    }

    if (lC !== priv(this || _global, "lengthCalculator")) {
      priv(this || _global, "lengthCalculator", lC);
      priv(this || _global, "length", 0);
      priv(this || _global, "lruList").forEach(function (hit) {
        hit.length = priv(this || _global, "lengthCalculator").call(this || _global, hit.value, hit.key);
        priv(this || _global, "length", priv(this || _global, "length") + hit.length);
      }, this || _global);
    }

    trim(this || _global);
  },
  get: function () {
    return priv(this || _global, "lengthCalculator");
  },
  enumerable: true
});
Object.defineProperty(LRUCache.prototype, "length", {
  get: function () {
    return priv(this || _global, "length");
  },
  enumerable: true
});
Object.defineProperty(LRUCache.prototype, "itemCount", {
  get: function () {
    return priv(this || _global, "lruList").length;
  },
  enumerable: true
});

LRUCache.prototype.rforEach = function (fn, thisp) {
  thisp = thisp || this || _global;

  for (var walker = priv(this || _global, "lruList").tail; walker !== null;) {
    var prev = walker.prev;
    forEachStep(this || _global, fn, walker, thisp);
    walker = prev;
  }
};

function forEachStep(self, fn, node, thisp) {
  var hit = node.value;

  if (isStale(self, hit)) {
    del(self, node);

    if (!priv(self, "allowStale")) {
      hit = undefined;
    }
  }

  if (hit) {
    fn.call(thisp, hit.value, hit.key, self);
  }
}

LRUCache.prototype.forEach = function (fn, thisp) {
  thisp = thisp || this || _global;

  for (var walker = priv(this || _global, "lruList").head; walker !== null;) {
    var next = walker.next;
    forEachStep(this || _global, fn, walker, thisp);
    walker = next;
  }
};

LRUCache.prototype.keys = function () {
  return priv(this || _global, "lruList").toArray().map(function (k) {
    return k.key;
  }, this || _global);
};

LRUCache.prototype.values = function () {
  return priv(this || _global, "lruList").toArray().map(function (k) {
    return k.value;
  }, this || _global);
};

LRUCache.prototype.reset = function () {
  if (priv(this || _global, "dispose") && priv(this || _global, "lruList") && priv(this || _global, "lruList").length) {
    priv(this || _global, "lruList").forEach(function (hit) {
      priv(this || _global, "dispose").call(this || _global, hit.key, hit.value);
    }, this || _global);
  }

  priv(this || _global, "cache", new Map()); // hash of items by key

  priv(this || _global, "lruList", new Yallist()); // list of items in order of use recency

  priv(this || _global, "length", 0); // length of items in the list
};

LRUCache.prototype.dump = function () {
  return priv(this || _global, "lruList").map(function (hit) {
    if (!isStale(this || _global, hit)) {
      return {
        k: hit.key,
        v: hit.value,
        e: hit.now + (hit.maxAge || 0)
      };
    }
  }, this || _global).toArray().filter(function (h) {
    return h;
  });
};

LRUCache.prototype.dumpLru = function () {
  return priv(this || _global, "lruList");
};

LRUCache.prototype.inspect = function (n, opts) {
  var str = "LRUCache {";
  var extras = false;
  var as = priv(this || _global, "allowStale");

  if (as) {
    str += "\n  allowStale: true";
    extras = true;
  }

  var max = priv(this || _global, "max");

  if (max && max !== Infinity) {
    if (extras) {
      str += ",";
    }

    str += "\n  max: " + util.inspect(max, opts);
    extras = true;
  }

  var maxAge = priv(this || _global, "maxAge");

  if (maxAge) {
    if (extras) {
      str += ",";
    }

    str += "\n  maxAge: " + util.inspect(maxAge, opts);
    extras = true;
  }

  var lc = priv(this || _global, "lengthCalculator");

  if (lc && lc !== naiveLength) {
    if (extras) {
      str += ",";
    }

    str += "\n  length: " + util.inspect(priv(this || _global, "length"), opts);
    extras = true;
  }

  var didFirst = false;
  priv(this || _global, "lruList").forEach(function (item) {
    if (didFirst) {
      str += ",\n  ";
    } else {
      if (extras) {
        str += ",\n";
      }

      didFirst = true;
      str += "\n  ";
    }

    var key = util.inspect(item.key).split("\n").join("\n  ");
    var val = {
      value: item.value
    };

    if (item.maxAge !== maxAge) {
      val.maxAge = item.maxAge;
    }

    if (lc !== naiveLength) {
      val.length = item.length;
    }

    if (isStale(this || _global, item)) {
      val.stale = true;
    }

    val = util.inspect(val, opts).split("\n").join("\n  ");
    str += key + " => " + val;
  });

  if (didFirst || extras) {
    str += "\n";
  }

  str += "}";
  return str;
};

LRUCache.prototype.set = function (key, value, maxAge) {
  maxAge = maxAge || priv(this || _global, "maxAge");
  var now = maxAge ? Date.now() : 0;
  var len = priv(this || _global, "lengthCalculator").call(this || _global, value, key);

  if (priv(this || _global, "cache").has(key)) {
    if (len > priv(this || _global, "max")) {
      del(this || _global, priv(this || _global, "cache").get(key));
      return false;
    }

    var node = priv(this || _global, "cache").get(key);
    var item = node.value; // dispose of the old one before overwriting

    if (priv(this || _global, "dispose")) {
      priv(this || _global, "dispose").call(this || _global, key, item.value);
    }

    item.now = now;
    item.maxAge = maxAge;
    item.value = value;
    priv(this || _global, "length", priv(this || _global, "length") + (len - item.length));
    item.length = len;
    this.get(key);
    trim(this || _global);
    return true;
  }

  var hit = new Entry(key, value, len, now, maxAge); // oversized objects fall out of cache automatically.

  if (hit.length > priv(this || _global, "max")) {
    if (priv(this || _global, "dispose")) {
      priv(this || _global, "dispose").call(this || _global, key, value);
    }

    return false;
  }

  priv(this || _global, "length", priv(this || _global, "length") + hit.length);
  priv(this || _global, "lruList").unshift(hit);
  priv(this || _global, "cache").set(key, priv(this || _global, "lruList").head);
  trim(this || _global);
  return true;
};

LRUCache.prototype.has = function (key) {
  if (!priv(this || _global, "cache").has(key)) return false;
  var hit = priv(this || _global, "cache").get(key).value;

  if (isStale(this || _global, hit)) {
    return false;
  }

  return true;
};

LRUCache.prototype.get = function (key) {
  return get(this || _global, key, true);
};

LRUCache.prototype.peek = function (key) {
  return get(this || _global, key, false);
};

LRUCache.prototype.pop = function () {
  var node = priv(this || _global, "lruList").tail;
  if (!node) return null;
  del(this || _global, node);
  return node.value;
};

LRUCache.prototype.del = function (key) {
  del(this || _global, priv(this || _global, "cache").get(key));
};

LRUCache.prototype.load = function (arr) {
  // reset the cache
  this.reset();
  var now = Date.now(); // A previous serialized cache has the most recent items first

  for (var l = arr.length - 1; l >= 0; l--) {
    var hit = arr[l];
    var expiresAt = hit.e || 0;

    if (expiresAt === 0) {
      // the item was created without expiration in a non aged cache
      this.set(hit.k, hit.v);
    } else {
      var maxAge = expiresAt - now; // dont add already expired items

      if (maxAge > 0) {
        this.set(hit.k, hit.v, maxAge);
      }
    }
  }
};

LRUCache.prototype.prune = function () {
  var self = this || _global;
  priv(this || _global, "cache").forEach(function (value, key) {
    get(self, key, false);
  });
};

function get(self, key, doUse) {
  var node = priv(self, "cache").get(key);

  if (node) {
    var hit = node.value;

    if (isStale(self, hit)) {
      del(self, node);
      if (!priv(self, "allowStale")) hit = undefined;
    } else {
      if (doUse) {
        priv(self, "lruList").unshiftNode(node);
      }
    }

    if (hit) hit = hit.value;
  }

  return hit;
}

function isStale(self, hit) {
  if (!hit || !hit.maxAge && !priv(self, "maxAge")) {
    return false;
  }

  var stale = false;
  var diff = Date.now() - hit.now;

  if (hit.maxAge) {
    stale = diff > hit.maxAge;
  } else {
    stale = priv(self, "maxAge") && diff > priv(self, "maxAge");
  }

  return stale;
}

function trim(self) {
  if (priv(self, "length") > priv(self, "max")) {
    for (var walker = priv(self, "lruList").tail; priv(self, "length") > priv(self, "max") && walker !== null;) {
      // We know that we're about to delete this one, and also
      // what the next least recently used key will be, so just
      // go ahead and set it now.
      var prev = walker.prev;
      del(self, walker);
      walker = prev;
    }
  }
}

function del(self, node) {
  if (node) {
    var hit = node.value;

    if (priv(self, "dispose")) {
      priv(self, "dispose").call(this || _global, hit.key, hit.value);
    }

    priv(self, "length", priv(self, "length") - hit.length);
    priv(self, "cache").delete(hit.key);
    priv(self, "lruList").removeNode(node);
  }
} // classy, since V8 prefers predictable objects.


function Entry(key, value, length, now, maxAge) {
  (this || _global).key = key;
  (this || _global).value = value;
  (this || _global).length = length;
  (this || _global).now = now;
  (this || _global).maxAge = maxAge || 0;
}

export default exports;